import React from 'react';
import classnames from 'classnames';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewRoom } from 'src/REST/capture';
import { useEntityConfigStore } from 'src/stores/entityConfig';
import texts, { analytics } from 'src/texts/texts';

import { dexieDb } from 'src/utils/indexedDb';

import BaseButton from 'src/components/BaseButton/BaseButton';
import ContentView from 'src/components/Content/Content';
import Footer from 'src/components/Footer/Footer';
import DamageHeader from 'src/components/Header/DamageHeader';
import Loading from 'src/components/Loading/Loading';
import Modal from 'src/components/Modal/Modal';
import SelectionGrid, { GridElement } from 'src/components/SelectionGrid/SelectionGrid';
import Text from 'src/components/Text/Text';

import * as log from 'src/utils/logger';

import type { AxiosError } from 'axios';
import { eventNames } from 'src/utils/events';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { TransitionProps } from '@mui/material/transitions';
import { useFeatureStore } from 'src/stores/featureStore';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const rooms = [texts.bathroom, texts.bedroom, texts.kitchen, texts.basement, texts.living, texts.laundry, texts.garage, texts.dining, texts.other];

const RoomSelectPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [roomType, setRoomType] = useState('');
  const [open, setOpen] = useState(false);
  const collectRoomName = useFeatureStore((state) => state.collectRoomName);

  const roomSelectCopy = useEntityConfigStore((state) => state.copy.roomSelect);

  const onSelect = (selectedRoom: string) => () => {
    log.event({
      event: eventNames.SELECT_ROOM,
      data: {
        page: 'Room Select Page',
        env: process.env.REACT_APP_ENV,
        selectedRoom,
      },
    });
    setRoomType(selectedRoom);
  };

  const handleClearDb = async () => {
    await dexieDb.pictures.clear();
  };

  const handleSelectRoom = async (roomName: string, roomDesc: string) => {
    const roomNameTrim = roomName?.trim();
    setOpen(false);
    if (roomType === '') return;
    setLoad(true);
    try {
      const { data } = await addNewRoom(params.claimId || '', roomType, roomNameTrim || roomType, roomDesc);
      if (data) {
        log.info({
          event: eventNames.ROOM_SELECTED_SUCCESS,
          data: {
            type: roomType,
            template: 'roomTypeSelection',
            header: 'Room Type Selection :house:',
            roomDesc: collectRoomName ? roomDesc || false : 'undefined',
            roomName: collectRoomName ? (Boolean(roomNameTrim) ? roomNameTrim : false) : 'undefined',
            roomId: data.roomId,
          },
        });
        await handleClearDb();
        navigate(`/${params.captureType}/${params.claimId}/${data.roomId}/damage-example`);
      }
    } catch (e) {
      const error = e as AxiosError;
      log.error({
        event: eventNames.ROOM_SELECTED_FAIL,
        ignoreSlack: false,
        data: { header: 'Room type selection error', error: e },
      });
      if (error?.response) {
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          setError(error?.response?.data as string);
        }
      }
      setRoomType('');
      setError('Unable to Continue. Please contact Administrator');
    } finally {
      setLoad(false);
    }
  };

  const handleClose = () => setLoad(false);

  const elementsWithData = rooms.map((room, index) => {
    const roomType = room.split(' ')?.[0] || 'other';
    return {
      text: room,
      name: roomType,
      className: classnames('basis-[calc(33%_-_5px)] pt-[calc(33%_-_5px)]', {
        'mt-2': index > 2,
        'mr-1': index % 3 === 0,
        'mx-1': index % 3 === 1,
        'ml-1': index % 3 === 2,
      }),
    };
  });

  const handleNext = () => {
    if (collectRoomName) setOpen(true);
    else handleSelectRoom(roomType, 'NA');
  };

  return (
    <>
      <DamageHeader hideRestart />
      <RoomName elements={elementsWithData.filter((e) => e.text === roomType)} roomType={roomType} open={open} handleClose={() => setOpen(false)} handleSave={handleSelectRoom} />
      <ContentView>
        <div className="w-full flex-1 flex flex-col items-center h-full pb-24">
          <div className="w-full desktops:w-desktop px-4 desktops:px-0 max-w-desktop pb-8">
            <div className="pb-3">
              <Text variant="title">{roomSelectCopy.title}</Text>
            </div>
            <Text variant="subtitle">{roomSelectCopy.subtitle}</Text>
          </div>

          <SelectionGrid checked={roomType} onClick={onSelect} elements={elementsWithData} />

          <Footer extraClassname="border-t bg-white pt-4">
            <BaseButton lightBg disabled={!roomType} name={analytics.selectRoom} onClick={handleNext} label={texts.next} dataTestId="handle-select-room" />
          </Footer>
          {error && <Modal handleClose={() => setError(null)} title="Caution" text={error} buttonText="Got it" />}
          <Loading open={load} handleClose={handleClose} />
        </div>
      </ContentView>
    </>
  );
};

const RoomName = ({
  open,
  handleClose,
  handleSave,
  roomType,
  elements,
}: {
  open: boolean;
  handleClose: () => void;
  handleSave: (value: string, description: string) => void;
  roomType: string;
  elements: GridElement[];
}) => {
  const handleClick = (roomData: string) => () => {};

  const [value, setValue] = useState('');
  const [desc, setDesc] = useState('');

  const isValid = (type: 'name' | 'desc') => {
    const maxLength = type === 'name' ? 35 : 250;
    const str = type === 'name' ? value : desc;
    const regex = type === 'name' ? /^[a-zA-Z0-9\s]+$/ : /^[a-zA-Z0-9\s.]+$/;
    if (str) {
      return str.length <= maxLength && regex.test(str);
    }
    return true;
  };

  const getErrorMessage = (type: 'name' | 'desc') => {
    const maxLength = type === 'name' ? 35 : 250;
    const regex = type === 'name' ? /^[a-zA-Z0-9\s]+$/ : /^[a-zA-Z0-9\s.]+$/;
    const str = type === 'name' ? value : desc;
    if (!isValid(type)) {
      if (str.length > maxLength) return `Please enter a value where Maximum Length is less than ${maxLength} characters.`;
      if (!regex.test(str)) return type === 'name' ? 'Please enter a value with only Alpha Numeric Characters' : 'Please enter a value with no special characters';
    }
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DamageHeader hideRestart handleBack={handleClose} />
      <ContentView>
        <div className="w-full flex flex-col pb-24 gap-10">
          <SelectionGrid checked={roomType} onClick={handleClick} elements={elements} />
          <div className="flex flex-col w-full">
            <Text extraClassname="text-justify" variant="title">
              Give this room a label
            </Text>
            <Text extraClassname="text-justify" variant="paragraph">
              Labels help if there are multiple rooms in this category such as bathrooms or bedrooms. Example label: My son's bedroom
            </Text>
            <TextField required placeholder="My Label" value={value} onChange={(evt) => setValue(evt.target.value)} error={!isValid('name')} helperText={getErrorMessage('name')} />
          </div>
          <div className="flex flex-col w-full">
            <Text extraClassname="text-justify" variant="title">
              Summarize damage in this room
            </Text>
            <small>(Optional)</small>
            <TextField
              placeholder="Describe in 1-2 sentences what happened. Your input will be shared with your insurance adjuster."
              multiline
              rows={4}
              value={desc}
              onChange={(evt) => setDesc(evt.target.value)}
              error={!isValid('desc')}
              helperText={getErrorMessage('desc')}
            />
          </div>
        </div>
        <Footer extraClassname="border-t bg-white pt-4">
          <BaseButton lightBg disabled={!(isValid('name') && isValid('desc'))} onClick={() => handleSave(value, desc)} label={texts.next} dataTestId="handle-select-room" />
        </Footer>
      </ContentView>
    </Dialog>
  );
};

export default RoomSelectPage;
